import React, { useMemo } from 'react';

import { useAssets } from '/hooks/useAssets';
import {
  getContributorName,
  getContributorImage
} from '/utils/prompt-submission';
import { objectBlank } from '/utils/object';

import { PromptSubmissionCard } from '/design-systems/Molecules/Cards/PromptSubmissionCard';
import { AssetCardPlaceholder } from '/design-systems/Molecules/Placeholders/AssetCardPlaceholder';
import { PageContainer } from '/design-systems/Molecules/PageContainer';
import { getPricesFromOrders } from '/utils/asset';

export const FeaturedHomeAsset = () => {
  const {
    // @prettier-ignore
    isLoadingAssets: isLoadingFeaturedHomeAsset,
    assets
  } = useAssets({
    assetsQueryKey: 'featured-home-assets',
    filters: {
      size: 1,
      featuredHome: true,
      orderBy: 'featuredScore',
      withAuctions: true
    }
  });

  const featuredHomeAsset = assets?.[0];
  const { price, lastSale, curatorFeePercentage, isAssetMinted } = useMemo(() => {
    const token = featuredHomeAsset?.AssetMint?.Token;
    return {
      ...getPricesFromOrders({
        orders: token?.Orders,
        listings: token?.Listings
      }),
      isAssetMinted: !objectBlank(token)
    };
  }, [featuredHomeAsset]);

  // if there is no featured home asset
  if (!isLoadingFeaturedHomeAsset && !featuredHomeAsset) {
    return <></>;
  }

  return (
    <PageContainer
      className="mt-6 bg-white px-6 dark:bg-neutral-900"
      pt="0"
      px="0"
      py="0"
      width="w-full max-w-[1280px]"
    >
      {isLoadingFeaturedHomeAsset || !featuredHomeAsset ? (
        <AssetCardPlaceholder variant="full-row" />
      ) : (
        <PromptSubmissionCard
          title={featuredHomeAsset?.title}
          description={featuredHomeAsset?.description}
          files={featuredHomeAsset?.Files || []}
          contributorImg={getContributorImage(featuredHomeAsset)}
          contributorName={getContributorName(featuredHomeAsset)}
          submission={featuredHomeAsset}
          noBorder={true}
          transparent
          className="w-full"
          saved={featuredHomeAsset?.saved}
          assetId={featuredHomeAsset.id}
          chain={featuredHomeAsset?.AssetMint?.Token?.chain}
          isAsset={true}
          isAssetMinted={isAssetMinted}
          showInRow={true}
          isFeaturedHome={true}
          staticImage={false}
          price={price}
          lastSale={lastSale}
          curatorFeePercentage={curatorFeePercentage}
          queryParam={+featuredHomeAsset.id}
          openFullPage={true}
          totalSupply={featuredHomeAsset?.AssetMint?.Token?.totalSupply}
          offerWindowDetails={
            featuredHomeAsset?.AssetMint?.Token?.Auctions?.[0]
          }
        />
      )}
    </PageContainer>
  );
};
