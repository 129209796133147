import React from 'react';
import { Team } from '../../Team';
import {
  PROJECT_INVITATION_EXPIRED_TITLE,
  PROJECT_INVITATION_EXPIRED_DESCRIPTION
} from '/config/project';
import { getBackgroundImageStyle } from '/utils/style';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { Button } from '/design-systems/Atoms/Button';

export const InviteModal = ({
  open,
  title,
  avatar,
  users,
  background,
  isBackgroundStaticFile,
  loading,
  expired,
  onClose,
  onAccept,
  modalTitle = 'Join Space'
}) => {
  return (
    <Modal autoHeight open={open} onClose={onClose} showCloseIconOnTop>
      <div className="flex flex-col gap-y-4">
        <Typography
          heading
          variant="h3"
          weight="semibold"
          className="text-center"
        >
          {modalTitle}
        </Typography>
        <div
          className="relative flex h-[230px] overflow-hidden rounded-xl bg-cover bg-center bg-no-repeat p-4"
          style={getBackgroundImageStyle({
            background,
            isStaticFile: isBackgroundStaticFile
          })}
        >
          <div className="absolute inset-0 h-full w-full bg-black/30"></div>

          <div className="z-[1] flex flex-col justify-end gap-y-2">
            {avatar && (
              <Avatar resolution="m" roundedSize="xl" src={avatar} size={80} />
            )}
            <Typography
              heading
              variant="h5"
              weight="semibold"
              overflow="truncate"
              color="#fff"
            >
              {title}
            </Typography>
            <div className="w-fit">
              <Team
                users={users}
                size={32}
                rounded
                labelClassName="text-white"
              />
            </div>
          </div>
        </div>

        {expired ? (
          <>
            <div>
              <Typography variant="medium" weight="bold" color="#991B1B">
                {PROJECT_INVITATION_EXPIRED_TITLE}
              </Typography>
              <Typography variant="medium" color="#B91C1C">
                {PROJECT_INVITATION_EXPIRED_DESCRIPTION}
              </Typography>
            </div>
            <Button color="black" variant="primary" blocked onClick={onClose}>
              Close
            </Button>
          </>
        ) : (
          <Button
            color="black"
            variant="primary"
            blocked
            disabled={loading}
            onClick={onAccept}
          >
            {loading ? 'Accepting' : 'Accept Invite'}
          </Button>
        )}
      </div>
    </Modal>
  );
};
