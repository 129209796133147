import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { ShowcaseCard } from '/design-systems/Molecules/Cards/ShowcaseCard';
import { emptyArray } from '/utils/object';

export const LoopingCarousel = ({ galleries, isLoading }) => {
  if (isLoading || emptyArray(galleries)) {
    return (
      <div className=" w-full px-6">
        <div className="mx-auto h-[520px] w-full max-w-[1280px] animate-pulse rounded-xl bg-neutral-200"></div>
      </div>
    );
  }

  return (
    <Swiper
      className="w-full max-w-full"
      spaceBetween={16}
      breakpoints={{
        320: {
          slidesPerView: 1.2
        },
        1600: {
          slidesPerView: 1.4
        },
        1800: {
          slidesPerView: 2
        },
        2400: {
          slidesPerView: 4
        }
      }}
      centeredSlides
      loop
    >
      {galleries.map((gallery) => (
        <SwiperSlide key={gallery.id}>
          {(props) => <SlideContent gallery={gallery} {...props} />}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const SlideContent = ({ gallery, isActive, isPrev, isNext }) => {
  const swiper = useSwiper();

  return (
    <ShowcaseCard
      gallery={gallery}
      disabledLink={!isActive}
      onClick={() => {
        if (isNext) swiper.slideNext();
        if (isPrev) swiper.slidePrev();
      }}
    />
  );
};
