import { useMutation, useQuery } from '@tanstack/react-query';
import { getFeaturedAssets } from '/services/joynApi/users/assets';
import { useMemo } from 'react';

export const useFeaturedAssets = () => {
  const { isLoading: isFetchingFeatured, data: featuredAssets } = useQuery(
    ['get-featured-assets'],
    () => getFeaturedAssets(),
    {
      select: (res) => res.data.data,
    }
  );

  return useMemo(
    () => ({
      isFetchingFeatured,
      featuredAssets,
    }),
    [isFetchingFeatured, featuredAssets]
  );
};
