import React from 'react';
import { useRouter } from 'next/router';

import { Typography } from "/design-systems/Atoms/Typography"
import { Button } from '/design-systems/Atoms/Button';

export const FeaturedSection = ({ id = '', title, children, href }) => {
  const router = useRouter();

  return (
    <section className="flex flex-col gap-y-4" id={id}>
      <Typography heading variant="h5" weight="semibold">
        {title}
      </Typography>
      {children}
      <div className="flex w-full justify-center">
        <Button
          className="w-fit items-center gap-x-1 px-3"
          color="primary"
          variant="secondary"
          onClick={() => router.push(href)}
        >
          <Typography variant="medium" weight="medium">
            View all
          </Typography>
        </Button>
      </div>
    </section>
  )
}
