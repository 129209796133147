import React from 'react';

import { Typography } from '/design-systems/Atoms/Typography';
import { ROUTES } from '/config/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';

export const PromoBanner = ({ title, icon, onClick }) => {
  return (
    <div className="mt-2 flex flex-col">
      {/* top gradient border */}
      <div className="bg-rainbow-gradient h-[2px] w-full"></div>

      {/* banner body */}
      <div
        className="cursor-pointer bg-neutral-600 px-6 py-3 text-white hover:bg-neutral-700 xl:px-0"
        onClick={onClick}
      >
        <div className="mx-auto flex w-full max-w-[1280px] items-center gap-2">
          {icon || <FontAwesomeIcon icon={faSparkles} className="h-5 w-5" />}

          {title}
        </div>
      </div>

      {/* bottom gradient border */}
      <div className="bg-rainbow-gradient h-[2px] w-full rotate-180"></div>
    </div>
  );
};
