import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { PromptSubmissionCard } from '/design-systems/Molecules/Cards/PromptSubmissionCard';
import { useRouter } from 'next/router';
import {
  getContributorImage,
  getContributorName
} from '/utils/prompt-submission';
import { getBackgroundUrl } from '/utils/style';
import { Button } from '/design-systems/Atoms/Button';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';

export const SpaceCard = ({
  slug,
  title,
  spaceUrl,
  coverUrl,
  logoUrl,
  queryParam,
  assets,
  assetCount
}) => {
  const router = useRouter();

  const handleViewSpaceClick = () => {
    router.push(spaceUrl);
  };

  return (
    <HyperlinkWrapper url={spaceUrl} onClick={false}>
      <div
        id={`space-card-${slug}`}
        className={classNames(
          'relative',
          'z-0',
          'flex',
          'w-full',
          'cursor-pointer',
          'flex-col',
          'gap-y-4',
          'overflow-hidden',
          'rounded-xl',
          'bg-black/90',
          'p-6',
          'text-neutral-50',
          `bg-[url('${getBackgroundUrl({
            background: coverUrl,
            dimension: 's'
          })}')]`,
          'space-card'
        )}
      >
        {/* background */}
        <div
          className={`absolute left-0 top-0 z-[-1] h-full w-full bg-black/40 backdrop-blur-lg`}
        />

        <div className="flex flex-col items-center gap-y-2 md:flex-row">
          <div className="flex w-full flex-1 items-center gap-x-4">
            <div>
              <Image
                src={logoUrl}
                className="h-[60px] min-h-[60px] w-[60px] min-w-[60px] rounded-xl border border-2 border-white/20 object-cover"
              />
            </div>
            <div>
              <Typography heading variant="h3" weight="semibold" className="">
                {title}
              </Typography>
            </div>
          </div>

          <div className="hidden items-center gap-x-8 md:flex">
            {assetCount && (
              <div className="flex flex-nowrap">
                <Typography variant="medium" weight="medium">
                  {assetCount} assets
                </Typography>
              </div>
            )}
            <Button
              variant="primary"
              color="white"
              className="font-semibold hover:bg-neutral-200"
              onClick={handleViewSpaceClick}
            >
              View Space
            </Button>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-6 gap-y-4 md:flex-row">
          {/* asset cards */}
          <div className="w-full overflow-x-auto">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
              {assets?.map((asset) => {
                return (
                  <PromptSubmissionCard
                    key={`asset-${asset?.id}`}
                    title={asset?.title}
                    description={asset?.description}
                    files={asset?.Files || []}
                    contributorImg={getContributorImage(asset)}
                    contributorName={getContributorName(asset)}
                    submission={asset}
                    noBorder={true}
                    transparent
                    className="w-full"
                    saved={asset?.saved}
                    assetId={asset.id}
                    chain={asset?.AssetMint?.Token?.chain}
                    isAsset={true}
                    queryParam={queryParam}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </HyperlinkWrapper>
  );
};
