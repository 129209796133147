import React from "react";

export const SpaceCardPlaceholder = ({}) => {
  return (
    <div className='relative z-0 flex w-full animate-pulse cursor-pointer flex-col gap-y-4 overflow-hidden rounded-xl bg-neutral-50 p-6'>
      <div className='flex flex-col items-center gap-y-2 lg:flex-row'>
        <div className='flex flex-1 items-center gap-x-4'>
          <div>
            <div className='h-[60px] w-[60px] rounded-xl border border-2 border-white bg-neutral-200 object-cover'></div>
          </div>
          <div className='h-4 w-[160px] rounded-full bg-neutral-200'></div>
        </div>
        <div className='flex items-center gap-x-8'>
          <div className='h-4 w-[160px] rounded-full bg-neutral-200'></div>
        </div>
      </div>

      <div className='flex items-center gap-x-6'>
        {/* asset cards */}
        <div className='w-full overflow-x-scroll'>
          <div className='flex min-w-[720px] gap-x-4'>
            <div className='h-[300px] w-[300px] rounded-xl bg-neutral-200'></div>
            <div className='h-[300px] w-[300px] rounded-xl bg-neutral-200'></div>
            <div className='h-[300px] w-[300px] rounded-xl bg-neutral-200'></div>
          </div>
        </div>
        {/* campaign card */}
        <div className='hidden h-[320px] min-w-[360px] flex-col gap-y-4 rounded-xl bg-neutral-200 p-4 lg:flex'></div>
      </div>
    </div>
  );
};
