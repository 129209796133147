import React from 'react';

export const CampaignCardPlaceholder = () => {
  return (
    <div className='flex w-full max-w-[420px] animate-pulse flex-col rounded-xl bg-neutral-50 p-4'>
      {/* Project meta and promptable type */}
      <div className='mb-2 flex w-full items-center justify-between'>
        <div className='h-9 w-36 rounded bg-neutral-200'></div>
        <div className='h-6 w-24 rounded-full bg-neutral-200'></div>
      </div>

      {/* title */}
      <div className='mb-2 h-6 w-full max-w-[240px] rounded-full bg-neutral-200'></div>

      {/* image */}
      <div className='mb-4 h-[200px] w-full rounded-xl bg-neutral-200'></div>

      {/* rewards */}
      <div className='mb-4 flex gap-2'>
        <div className='h-6 w-24 rounded-full bg-neutral-200'></div>
        <div className='h-6 w-24 rounded-full bg-neutral-200'></div>
      </div>

      {/* countdown and contributors */}
      <div className='h-4 w-20 rounded-full bg-neutral-200'></div>
    </div>
  );
};
